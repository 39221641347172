import { Routes } from "@angular/router";

export const content: Routes = [
  {
    path: 'pets',
    loadChildren: () => import("../../components/pets/pets.module").then(m => m.PetsModule)
  },
  {
    path: 'requests',
    loadChildren: () => import("../../components/requests/requests.module").then(m => m.RequestsModule)
  },
  {
    path: 'articles',
    loadChildren: () => import("../../components/articles/articles.module").then(m => m.ArticlesModule)
  },
  {
    path: 'users',
    loadChildren: () => import("../../components/users/users.module").then(m => m.UsersModule)
  },
  {
    path: 'user/:id',
    loadChildren: () => import("../../components/user/user.module").then(m => m.UserModule)
  },
  {
    path: 'dash',
    loadChildren: () => import("../../components/dash/dash.module").then(m => m.DashModule)
  }
];
